.carousel-item {
    height: 500px;
}

.carousel-item img {
    height: 500px;
}

@media only screen and (max-width: 768px) {
    .carousel-item {
        height: 300px;
    }

    .carousel-item img {
        height: 300px;
    }

}