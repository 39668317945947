/* /* .services{
    padding: 90px;
    text-align: center;
} */

.header {
    margin-top: 20px;
    text-align: center;
}

.card-componenet {
    width: 70%;
    margin: auto;
}

.card-title {
    text-align: center;
}