.contact-us {
    padding: 35px;
}

.contact-us-image {
    height: 400px;
}

.hidden-class {
    display: none;
}

.contact-us-heading {
    margin-top: 30px;
    margin-left: 30px;
}

.contact-us-page {
    background: #f8f8f8;
}

.submit-button {
    text-align: center;
}

.location {
    margin-top: 7px;
    margin-left: 41px;
}

@media only screen and (max-width: 768px) {
    .location {
        margin-top: 15px;
        margin-left: 0px;
    }
}